import React, { useState, useEffect } from 'react';
import { Link, withRouter, Redirect } from 'react-router-dom';

import { compose } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';

import { useFormik, Field, FieldArray, FormikProvider } from 'formik';
import DatePicker from "react-datepicker";
import { updateReciept, getReciept } from '../../store/actions/receiptActions';
import 'react-datepicker/dist/react-datepicker.css';
import qs from 'qs';

const PutReciept = ({ auth, getReciept, reciept: { reciept, isLoading, error }, history, updateReciept, match }) => {
    const [initialValues, setInitialValues] = useState({
        account: "",
        customerName: "",
        customerContact: "",
        manager: "",
        productName: "",
        standard: "",
        quantity: "",
        dueDate: "",
        bowNumber: "",
        bookbindery: "",
        printingPress: "",
        coverSource: "",
        memo1: "",
        memo2: "",
        memo3: "",
        memo4: "",
        memo5: "",
        memo6: "",
        memo7: "",
        bindingMethod: "",
        gwidori: "",
        ribbon: "",
        gildEdge: "",
        headBand: "",
        shrinkWrap: "",
        barcode: "",
        composition: [],
        currentProcess: "",
        totalComposition: "",
        processTypes: [
            {
                processType: "인쇄",
                memo: "",
            },
            {
                processType: "재단",
                memo: "",
            },
            {
                processType: "접지",
                memo: ""
            },
            {
                processType: "노리",
                memo: ""
            },
            {
                processType: "정합",
                memo: ""
            },
            {
                processType: "사철",
                memo: ""
            },
            {
                processType: "가다미",
                memo: ""
            },
            {
                processType: "삼면재단",
                memo: ""
            },
            {
                processType: "후가공",
                memo: ""
            },
            {
                processType: "성책",
                memo: ""
            },
            {
                processType: "포장",
                memo: ""
            }
        ]
    });

    const formik = useFormik({
        initialValues,
        onSubmit: (values) => {
            updateReciept(reciept.id, values, history);
            return <Redirect to="/reciepts" />;
        },
    });

    useEffect(() => {
        const matchID = match.params.id;

        getReciept(matchID, history);
    }, []); // 마운트 후에만 실행

    useEffect(() => {
        let date;

        if (reciept?.dueDate) {
            date = new Date(reciept.dueDate);
        }


        formik.setValues({
            ...formik.values,
            account: reciept?.account,
            customerName: reciept?.customerName,
            customerContact: reciept?.customerContact,
            manager: reciept?.manager,
            productName: reciept?.productName,
            standard: reciept?.standard,
            quantity: reciept?.quantity,
            bowNumber: reciept?.bowNumber,
            dueDate: date,
            bookbindery: reciept?.bookbindery,
            printingPress: reciept?.printingPress,
            coverSource: reciept?.coverSource,
            memo1: reciept?.memo1,
            memo2: reciept?.memo2,
            memo3: reciept?.memo3,
            memo4: reciept?.memo4,
            memo5: reciept?.memo5,
            memo6: reciept?.memo6,
            memo7: reciept?.memo7,
            bindingMethod: reciept?.bindingMethod,
            gwidori: reciept?.gwidori,
            ribbon: reciept?.ribbon,
            gildEdge: reciept?.gildEdge,
            headBand: reciept?.headBand,
            shrinkWrap: reciept?.shrinkWrap,
            barcode: reciept?.barcode,
            composition: reciept?.composition || [],
            currentProcess: reciept?.currentProcess,
            totalComposition: reciept?.totalComposition,
            processTypes: reciept?.processType?.slice(1, reciept.processType?.length - 2).map((m, index) => {
                var foundUser = "";
                for (let i = 0; i < reciept.processTypeMemo.length; i++) {
                    if (reciept.processTypeMemo[i].processTypeIndex === index+1) {
                        foundUser = reciept.processTypeMemo[i].memo;
                        break;
                    }
                }
                return {
                    processType: m,
                    memo: foundUser,
                }
            }) || []
        });
    }, [reciept]);

    return (
        <div>
            <div className="container">
                <br />
                <h1>주문 접수</h1><Link className="bold" to="/reciepts">
                    돌아가기
                </Link>
                <form onSubmit={formik.handleSubmit} noValidate>
                    <FormikProvider value={formik}>
                        <hr class="hr" />
                        <div>
                            <h4>고객 정보</h4>
                            <br />
                            <div class="row mb-4">
                                <div class="col">
                                    <div class="form-outline">
                                        <input
                                            placeholder="거래처명"
                                            name="account"
                                            id="1"
                                            className="form-control"
                                            type="text"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.account}
                                        />
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-outline">
                                        <input
                                            placeholder="거래처 담당자"
                                            name="customerName"
                                            id="2"
                                            className="form-control"
                                            type="text"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.customerName}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="form-outline mb-4">
                                <input
                                    placeholder="연락처"
                                    name="customerContact"
                                    id="3"
                                    className="form-control"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.customerContact}
                                />
                            </div>
                            <div class="form-outline mb-4">
                                <input
                                    placeholder="제본소"
                                    name="bookbindery"
                                    id="3"
                                    className="form-control"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.bookbindery}
                                />
                            </div>
                            <div class="form-outline mb-4">
                                <input
                                    placeholder="인쇄소"
                                    name="printingPress"
                                    id="3"
                                    className="form-control"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.printingPress}
                                />
                            </div>
                            <div class="form-outline mb-4">
                                <input
                                    placeholder="표지 출처"
                                    name="coverSource"
                                    id="3"
                                    className="form-control"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.coverSource}
                                />
                            </div>
                            <hr class="hr" />
                            <h4>주문 정보</h4>
                            <br />
                            <div class="row mb-4">
                                <div class="col">
                                    <div class="form-outline">
                                        <input
                                            placeholder="제품명"
                                            name="productName"
                                            id="5"
                                            className="form-control"
                                            type="text"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.productName}
                                        />
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-outline">
                                        <input
                                            placeholder="규격"
                                            name="standard"
                                            id="6"
                                            className="form-control"
                                            type="text"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.standard}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col">
                                    <div class="form-outline">
                                        <input
                                            placeholder="수량"
                                            name="quantity"
                                            id="7"
                                            className="form-control"
                                            type="text"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.quantity}
                                        />
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-outline">
                                        <input
                                            placeholder="절수"
                                            name="bowNumber"
                                            id="8"
                                            className="form-control"
                                            type="text"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.bowNumber}
                                        />
                                    </div>
                                </div>
                            </div>
                            <DatePicker
                                showIcon
                                placeholderText='납기일'
                                selected={formik.values.dueDate}
                                className="form-control"
                                dateFormat="yyyy-MM-dd"
                                wrapperClassName="datepicker"
                                name="dueDate"
                                onChange={date => formik.setFieldValue('dueDate', date)}
                            />
                            <br /><br />
                            <div class="form-outline mb-4">
                                <input
                                    placeholder="특이사항 메모"
                                    name="memo1"
                                    id="8"
                                    className="form-control"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.memo1}
                                />
                            </div>
                            <div class="form-outline mb-4">
                                <input
                                    placeholder="특이사항2 메모"
                                    name="memo2"
                                    id="8"
                                    className="form-control"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.memo2}
                                />
                            </div>
                            <div class="form-outline mb-4">
                                <input
                                    placeholder="특이사항3 메모"
                                    name="memo3"
                                    id="8"
                                    className="form-control"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.memo3}
                                />
                            </div>
                            <div class="form-outline mb-4">
                                <input
                                    placeholder="특이사항4 메모"
                                    name="memo4"
                                    id="8"
                                    className="form-control"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.memo4}
                                />
                            </div>
                            <div class="form-outline mb-4">
                                <input
                                    placeholder="특이사항5 메모"
                                    name="memo5"
                                    id="8"
                                    className="form-control"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.memo5}
                                />
                            </div>
                            <div class="form-outline mb-4">
                                <input
                                    placeholder="특이사항6 메모"
                                    name="memo6"
                                    id="8"
                                    className="form-control"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.memo6}
                                />
                            </div>
                            <div class="form-outline mb-4">
                                <input
                                    placeholder="특이사항7 메모"
                                    name="memo7"
                                    id="8"
                                    className="form-control"
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.memo7}
                                />
                            </div>
                        </div>
                        <hr class="hr" />
                        <h4>페이지 구성</h4>
                        <br />
                        <FieldArray
                            name="composition"
                            render={({ insert, remove, push }) => (
                                <div>
                                    {formik.values.composition.length > 0 &&
                                        formik.values.composition.map((co, index) => (
                                            <div className="row" key={index} style={{ marginTop: "10px" }}>
                                                <div className="col">
                                                    <Field
                                                        name={`composition.${index}.description`}
                                                        placeholder="내용"
                                                        className="form-control"
                                                        type="text"
                                                    />
                                                    {formik.errors.composition &&
                                                        formik.errors.composition[index] &&
                                                        formik.errors.composition[index].description &&
                                                        formik.touched.composition &&
                                                        formik.touched.composition[index].description && (
                                                            <div className="field-error">
                                                                {formik.errors.composition[index].description}
                                                            </div>
                                                        )}
                                                </div>
                                                <div className="col">
                                                    <Field
                                                        name={`composition.${index}.quantity`}
                                                        placeholder="평량"
                                                        className="form-control"
                                                        type="text"
                                                    />
                                                    {formik.errors.composition &&
                                                        formik.errors.composition[index] &&
                                                        formik.errors.composition[index].quantity &&
                                                        formik.touched.composition &&
                                                        formik.touched.composition[index].quantity && (
                                                            <div className="field-error">
                                                                {formik.errors.composition[index].quantity}
                                                            </div>
                                                        )}
                                                </div>
                                                <div className="col">
                                                    <Field
                                                        name={`composition.${index}.paper`}
                                                        placeholder="지종"
                                                        className="form-control"
                                                        type="text"
                                                    />
                                                    {formik.errors.composition &&
                                                        formik.errors.composition[index] &&
                                                        formik.errors.composition[index].paper &&
                                                        formik.touched.composition &&
                                                        formik.touched.composition[index].paper && (
                                                            <div className="field-error">
                                                                {formik.errors.composition[index].paper}
                                                            </div>
                                                        )}
                                                </div>
                                                <div className="col">
                                                    <Field
                                                        name={`composition.${index}.page`}
                                                        placeholder="페이지"
                                                        className="form-control"
                                                        type="text"
                                                        validate={(value) => {
                                                            if (!/^\d+$/.test(value)) {
                                                                return "숫자만 입력해주세요.";
                                                            }
                                                        }}
                                                    />
                                                    {formik.errors.composition &&
                                                        formik.errors.composition[index] &&
                                                        formik.errors.composition[index].page &&
                                                        formik.touched.composition &&
                                                        formik.touched.composition[index]?.page && (  // composition[index]가 undefined일 경우를 대비하여 옵셔널 체이닝 연산자 사용
                                                            <div className="field-error">
                                                                {formik.errors.composition[index].page}
                                                            </div>
                                                        )}
                                                </div>
                                                <div className="col">
                                                    <Field
                                                        name={`composition.${index}.insa`}
                                                        placeholder="인쇄"
                                                        className="form-control"
                                                        type="text"
                                                    />
                                                    {formik.errors.composition &&
                                                        formik.errors.composition[index] &&
                                                        formik.errors.composition[index].insa &&
                                                        formik.touched.composition &&
                                                        formik.touched.composition[index].insa && (
                                                            <div className="field-error">
                                                                {formik.errors.composition[index].insa}
                                                            </div>
                                                        )}
                                                </div>
                                                <div className="col">
                                                    <Field
                                                        name={`composition.${index}.memo`}
                                                        placeholder="비고"
                                                        className="form-control"
                                                        type="text"
                                                    />
                                                    {formik.errors.composition &&
                                                        formik.errors.composition[index] &&
                                                        formik.errors.composition[index].memo &&
                                                        formik.touched.composition &&
                                                        formik.touched.composition[index].memo && (
                                                            <div className="field-error">
                                                                {formik.errors.composition[index].memo}
                                                            </div>
                                                        )}
                                                </div>
                                                <div className="col">
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-danger"
                                                        onClick={() => remove(index)}
                                                        style={{ width: "50px" }}
                                                    >
                                                        X
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary"
                                        onClick={() => push({ description: "", paper: "" })}
                                        style={{ width: "100px", marginTop: "10px" }}
                                    >
                                        추가
                                    </button>
                                    <br /><br />
                                    <input
                                        placeholder="내지구성"
                                        name="totalComposition"
                                        className="form-control"
                                        type="text"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.totalComposition}
                                    />
                                </div>
                            )}
                        />
                        <br /><br />
                        <div class="row mb-4">
                            <div class="col">
                                <div class="form-outline">
                                    <input
                                        placeholder="제본방법"
                                        name="bindingMethod"
                                        className="form-control"
                                        type="text"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.bindingMethod}
                                    />
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-outline">
                                    <input
                                        placeholder="귀도리"
                                        name="gwidori"
                                        className="form-control"
                                        type="text"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.gwidori}
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col">
                                <div class="form-outline">
                                    <input
                                        placeholder="리본"
                                        name="ribbon"
                                        className="form-control"
                                        type="text"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.ribbon}
                                    />
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-outline">
                                    <input
                                        placeholder="금장"
                                        name="gildEdge"
                                        id="2"
                                        className="form-control"
                                        type="text"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.gildEdge}
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col">
                                <div class="form-outline">
                                    <input
                                        placeholder="헤드밴드"
                                        name="headBand"
                                        className="form-control"
                                        type="text"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.headBand}
                                    />
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-outline">
                                    <input
                                        placeholder="포장"
                                        name="shrinkWrap"
                                        className="form-control"
                                        type="text"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.shrinkWrap}
                                    />
                                </div>
                            </div>
                        </div>
                        <br />
                        <hr class="hr" />
                        <h4>공정 선택</h4>
                        <FieldArray
                            name="processTypes"
                            render={({ insert, remove, push }) => (
                                <div>
                                    {formik.values.processTypes.length > 0 &&
                                        formik.values.processTypes.map((co, index) => (
                                            <div className="row" key={index} style={{ marginTop: "10px" }}>
                                                <div className="col">
                                                    <Field
                                                        name={`processTypes.${index}.processType`}
                                                        placeholder="내용"
                                                        className="form-control"
                                                        type="text"
                                                    />
                                                    {formik.errors.processTypes &&
                                                        formik.errors.processTypes[index] &&
                                                        formik.errors.processTypes[index].processType &&
                                                        formik.touched.processTypes &&
                                                        formik.touched.processTypes[index].processType && (
                                                            <div id="validationServerUsernameFeedback" class="invalid-feedback">
                                                                {formik.errors.processTypes[index].processType}
                                                            </div>
                                                        )}
                                                </div>
                                                <div className="col">
                                                    <Field
                                                        name={`processTypes.${index}.memo`}
                                                        placeholder="수량 및 특이사항"
                                                        className="form-control"
                                                        type="text"
                                                    />
                                                    {formik.errors.processTypes &&
                                                        formik.errors.processTypes[index] &&
                                                        formik.errors.processTypes[index].memo &&
                                                        formik.touched.processTypes &&
                                                        formik.touched.processTypes[index].memo && (
                                                            <div id="validationServerUsernameFeedback" class="invalid-feedback">
                                                                {formik.errors.processTypes[index].memo}
                                                            </div>
                                                        )}
                                                </div>
                                                <div className="col">
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-danger"
                                                        onClick={() => remove(index)}
                                                        style={{ width: "50px" }}
                                                    >
                                                        X
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary"
                                        onClick={() => push({ description: "", paper: "" })}
                                        style={{ width: "100px", marginTop: "10px" }}
                                    >
                                        추가
                                    </button>
                                    <br /><br />
                                </div>
                            )}
                        />
                        <br />
                        <div>
                            <button className="btn btn-dark" type="submit" disabled={isLoading || !formik.isValid}>
                                생성
                            </button>
                        </div>
                    </FormikProvider>
                </form>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    reciept: state.reciept,
});

export default compose(withRouter, connect(mapStateToProps, { getReciept, updateReciept }))(PutReciept);
