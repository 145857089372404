import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Layout from '../../layout/Layout';
import MessageList from '../../components/MessageList/MessageList';
import MessageForm from '../../components/MessageForm/MessageForm';
import { reseedDatabase } from '../../store/actions/authActions';

import './styles.css';

const Home = ({ auth, reseedDatabase }) => {
  const handleReseed = () => {
    reseedDatabase();
  };

  if (auth.isAuthenticated && auth.me.role == "OUTSIDER") {
    return (
      <Layout>
        <div className="home-page">
          <br/>
          <h1>Home page</h1>
          {!auth.isAuthenticated ? (
            <div>
              <p>
                Welcome guest!{' '}
                <Link className="bold" to="/login">
                  Log in
                </Link>{' '}
              </p>
            </div>
          ) : (
            <>
              <p>
                Welcome Sonoma ERP!
              </p>
            </>
          )}
        </div>
      </Layout>
    )
  }

  return (
    <Layout>
      <div className="home-page">
        <br/>
        <h1>Home page</h1>
        {!auth.isAuthenticated ? (
          <div>
            <p>
              Welcome guest!{' '}
              <Link className="bold" to="/login">
                Log in
              </Link>{' '}
            </p>
          </div>
        ) : (
          <>
            <p>
              Welcome Sonoma ERP!
            </p>
            <MessageForm />
          </>
        )}
        <MessageList />
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default compose(connect(mapStateToProps, { reseedDatabase }))(Home);
