import React, { useEffect, useState } from 'react';
import { getRecieptsByProcessType, putReciepts } from '../../store/actions/receiptsActions';
import { putReciept } from '../../store/actions/receiptActions';
import { Redirect } from 'react-router-dom';

import { compose } from 'redux';
import { connect } from 'react-redux';
import Layout from '../../layout/Layout';
import _ from 'lodash';
import requireAuth from '../../hoc/requireAuth';
import { Button, Table } from 'react-bootstrap';
import qs from 'qs';
import { Nav, NavItem } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

const RecieptsProcessType = ({ auth, getRecieptsByProcessType, putReciept, putReciepts, history, reciepts: { reciepts, isLoading, error }, location }) => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedOption, setSelectedOption] = useState("");
    const [searchQuery, setSearchQuery] = useState('');
    useEffect(() => {
        const query = qs.parse(location.search, {
            ignoreQueryPrefix: true
        });
        getRecieptsByProcessType(query.processType);
    }, []);
    const query = qs.parse(location.search, {
        ignoreQueryPrefix: true
    });
    const activeClass = (tabName) => query.processType === tabName ? "active" : "";
    const handlePutReciept = (id, history) => {
        putReciept(id, history);
        window.location.reload();
    };
    const [inputValue, setInputValue] = useState("");

    const handleSubmit = async () => {
        putReciept(inputValue, history);
        window.location.reload();
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    if (!auth.isAuthenticated) return <Redirect to="/" />;

    const handleItemSelect = (e, id) => {
        if (e.target.checked) {
            setSelectedItems([...selectedItems, id]);
        } else {
            setSelectedItems(selectedItems.filter(itemId => itemId !== id));
        }
    };

    const handleBulkAction = () => {
        putReciepts({
            ids: selectedItems,
            processType: selectedOption,
        })
    };

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    return (
        <Layout>
            <div class="event-schedule-area-two bg-color pad100">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <br />
                            <Nav variant="tabs" defaultActiveKey={`${activeClass("")}`} className="custom-tab justify-content-center">
                                <NavItem>
                                    <Nav.Link eventKey="all" href="/process-control">전체</Nav.Link>
                                </NavItem>
                                <NavItem>
                                    <Nav.Link className={`${activeClass("접수")}`} eventKey="foundation" href="/reciepts/process-type?processType=접수">접수</Nav.Link>
                                </NavItem>
                                <NavItem>
                                    <Nav.Link className={`${activeClass("인쇄")}`} eventKey="foundation" href="/reciepts/process-type?processType=인쇄">인쇄</Nav.Link>
                                </NavItem>
                                <NavItem>
                                    <Nav.Link className={`${activeClass("재단")}`} eventKey="foundation" href="/reciepts/process-type?processType=재단">재단</Nav.Link>
                                </NavItem>
                                <NavItem>
                                    <Nav.Link className={`${activeClass("접지")}`} eventKey="folding" href="/reciepts/process-type?processType=접지">접지</Nav.Link>
                                </NavItem>
                                <NavItem>
                                    <Nav.Link className={`${activeClass("노리")}`} eventKey="gluing" href="/reciepts/process-type?processType=노리">노리</Nav.Link>
                                </NavItem>
                                <NavItem >
                                    <Nav.Link className={`${activeClass("정합")}`} eventKey="aligning" href="/reciepts/process-type?processType=정합">정합</Nav.Link>
                                </NavItem>
                                <NavItem className="mr-0">
                                    <Nav.Link className={`${activeClass("사철")}`} eventKey="sachi" href="/reciepts/process-type?processType=사철">사철</Nav.Link>
                                </NavItem>
                                <NavItem className="mr-0">
                                    <Nav.Link className={`${activeClass("가다미")}`} eventKey="gadami" href="/reciepts/process-type?processType=가다미">가다미</Nav.Link>
                                </NavItem>
                                <NavItem className="mr-0">
                                    <Nav.Link className={`${activeClass("삼면재단")}`} eventKey="three-side" href="/reciepts/process-type?processType=삼면재단">삼면재단</Nav.Link>
                                </NavItem>
                                <NavItem className="mr-0">
                                    <Nav.Link className={`${activeClass("후가공")}`} eventKey="post-processing" href="/reciepts/process-type?processType=후가공">후가공</Nav.Link>
                                </NavItem>
                                <NavItem className="mr-0">
                                    <Nav.Link className={`${activeClass("성책")}`} eventKey="binding" href="/reciepts/process-type?processType=성책">성책</Nav.Link>
                                </NavItem>
                                <NavItem className="mr-0">
                                    <Nav.Link className={`${activeClass("포장")}`} eventKey="binding" href="/reciepts/process-type?processType=포장">포장</Nav.Link>
                                </NavItem>
                                <NavItem className="mr-0">
                                    <Nav.Link className={`${activeClass("제작완료")}`} eventKey="completion" href="/reciepts/process-type?processType=제작완료">제작완료</Nav.Link>
                                </NavItem>
                                <NavItem className="mr-0">
                                    <Nav.Link className={`${activeClass("납품완료")}`} eventKey="delivery" href="/reciepts/process-type?processType=납품완료">납품완료</Nav.Link>
                                </NavItem>
                            </Nav>
                            <div className="container mt-5">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                        <form onSubmit={handleSubmit}>
                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control" placeholder="바코드" aria-label="바코드" aria-describedby="basic-addon2" value={inputValue} onChange={handleInputChange} />
                                                <div className="input-group-append" style={{marginBottom: "0px", marginLeft: "5px"}}>
                                                    <button className="btn btn-outline-secondary" type="submit">공정 넘기기</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <Form inline className="mr-2">
                                            <Form.Select size="sm" as="select" value={selectedOption} onChange={handleOptionChange}>
                                                <option value="">공정 선택</option>
                                                <option value="접수">접수</option>
                                                <option value="인쇄">인쇄</option>
                                                <option value="재단">재단</option>
                                                <option value="접지">접지</option>
                                                <option value="노리">노리</option>
                                                <option value="정합">정합</option>
                                                <option value="사철">사철</option>
                                                <option value="가다미">가다미</option>
                                                <option value="삼면재단">삼면재단</option>
                                                <option value="성책">성책</option>
                                                <option value="포장">포장</option>
                                                <option value="제작완료">제작완료</option>
                                                <option value="납품완료">납품완료</option>
                                            </Form.Select>
                                        </Form>
                                        <Button size="sm" variant="primary" onClick={() => handleBulkAction(selectedOption)} disabled={selectedItems.length === 0} className="mx-2">
                                            일괄 처리
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <Table bordered hover>
                                    <thead className="table-active">
                                        <tr>
                                            <th></th>
                                            <th>ID</th>
                                            <th>거래처</th>
                                            <th>제품명</th>
                                            <th>수량</th>
                                            <th>절수(규격)</th>
                                            <th>접수일</th>
                                            <th>납기일</th>
                                            <th>현재 단계</th>
                                            <th>기능</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {reciepts.map((r, i) => {
                                            const isChecked = selectedItems.includes(r.id);
                                            return (
                                                <tr key={r.id} className="inner-box">
                                                    <td><input type="checkbox" checked={isChecked} onChange={e => handleItemSelect(e, r.id)} /></td>
                                                    <td>
                                                        <Link to={`/reciepts/${r.id}`}>
                                                            {r.barcode}
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        {r.account}
                                                    </td>
                                                    <td>
                                                        {r.productName}
                                                    </td>
                                                    <td>
                                                        {r.quantity}
                                                    </td>
                                                    <td>
                                                        {r.bowNumber}({r.standard})
                                                    </td>
                                                    <td>
                                                        {r.createdAt?.substring(0, 10)}
                                                    </td>
                                                    <td>
                                                        {r.dueDate?.substring(0, 10)}
                                                    </td>
                                                    <td>
                                                        {r.processType[r.currentProcess]}
                                                    </td>
                                                    <td>
                                                        <button
                                                            onClick={() => handlePutReciept(r.barcode, history)}
                                                            type="button"
                                                            className="btn btn-outline-primary"
                                                        >
                                                            공정 넘기기
                                                        </button>

                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    reciepts: state.reciepts,
});

export default compose(requireAuth, connect(mapStateToProps, { getRecieptsByProcessType, putReciept, putReciepts }))(RecieptsProcessType);
