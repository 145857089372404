import axios from 'axios';

import { attachTokenToHeaders } from './authActions';
import {
  GET_RECIEPT_LOADING,
  GET_RECIEPT_SUCCESS,
  GET_RECIEPT_FAIL,
  CREATE_RECIEPT_LOADING,
  CREATE_RECIEPT_SUCCESS,
  CREATE_RECIEPT_FAIL,
} from '../types';

export const getReciept = (id, history) => async (dispatch, getState) => {
  dispatch({
    type: GET_RECIEPT_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(`/api/reciepts/${id}`, options);

    dispatch({
      type: GET_RECIEPT_SUCCESS,
      payload: { reciept: response.data.reciept },
    });
  } catch (err) {
    if (err?.response.status === 404) {
      history.push('/notfound');
    }
    dispatch({
      type: GET_RECIEPT_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const getRecieptByBarcode = (id, history) => async (dispatch, getState) => {
  dispatch({
    type: GET_RECIEPT_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(`/api/reciepts/barcode/${id}`, options);

    dispatch({
      type: GET_RECIEPT_SUCCESS,
      payload: { reciept: response.data.reciept },
    });

    return response
  } catch (err) {
    dispatch({
      type: GET_RECIEPT_FAIL,
      payload: { reciept: undefined },
    });

    return err.response
  }
};

export const createReciept = (formData, history) => async (dispatch, getState) => {
  dispatch({ type: CREATE_RECIEPT_LOADING });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.post('api/reciepts', formData, options);
    dispatch({
      type: CREATE_RECIEPT_SUCCESS,
      payload: { reciept: response.data.message },
    });
    history.push('/reciepts');
  } catch (err) {
    dispatch({
      type: CREATE_RECIEPT_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const duplicateReciept = (id, formData) => async (dispatch, getState) => {
  dispatch({ type: CREATE_RECIEPT_LOADING });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.post(`/api/reciepts/${id}/duplicated`,formData, options);
    dispatch({
      type: CREATE_RECIEPT_SUCCESS,
      payload: { reciept: response.data.reciept },
    });
  } catch (err) {
    dispatch({
      type: CREATE_RECIEPT_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const deleteReciept = (id) => async (dispatch, getState) => {
  dispatch({ type: CREATE_RECIEPT_LOADING });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.delete(`/api/reciepts/${id}`, options);
    dispatch({
      type: CREATE_RECIEPT_SUCCESS,
      payload: { reciept: response.data.reciept },
    });
  } catch (err) {
    dispatch({
      type: CREATE_RECIEPT_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const updateReciept = (id, formData, history) => async (dispatch, getState) => {
  dispatch({ type: CREATE_RECIEPT_LOADING });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.put(`/api/reciepts/${id}`,formData, options);
    dispatch({
      type: CREATE_RECIEPT_SUCCESS,
      payload: { reciept: response.data.reciept },
    });
    history.push(`/reciepts/${id}`);
  } catch (err) {
    dispatch({
      type: CREATE_RECIEPT_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};


export const putReciept = (id, history) => async (dispatch, getState) => {
  dispatch({ type: CREATE_RECIEPT_LOADING });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.post(`/api/reciepts/${id}/next-process`,null, options);
    dispatch({
      type: CREATE_RECIEPT_SUCCESS,
      payload: { reciept: response.data.reciept },
    });
    history.push('/process-control');
  } catch (err) {
    dispatch({
      type: CREATE_RECIEPT_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};
