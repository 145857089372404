import React, { useEffect, useState, useRef } from 'react';
import { getReciepts, putReciepts } from '../../store/actions/receiptsActions';
import { duplicateReciept, deleteReciept } from '../../store/actions/receiptActions';
import { Redirect } from 'react-router-dom';
import ReactToPrint from "react-to-print";
import { compose } from 'redux';
import { connect } from 'react-redux';
import axios from 'axios';
import Layout from '../../layout/Layout';
import _ from 'lodash';
import requireAuth from '../../hoc/requireAuth';
import {
  Link,
} from 'react-router-dom';
import { Button, Table, Pagination } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

const Reciepts = ({ auth, getReciepts, reciepts: { reciepts, isLoading, error }, putReciepts, duplicateReciept, deleteReciept, history }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [duplicatedId, setDuplicatedId] = useState(undefined);
  const [deletedId, setDeletedId] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [data, setData] = useState([]);

  const itemsPerPage = 20; // You can adjust the number of items per page
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = reciepts.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(reciepts.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const fetchData = async (id) => {
    try {
      const response = await axios.get(`https://sonomamg.co.kr/api/reciepts/${id}/history`); // API를 호출합니다.
      setData(response.data); // API 응답 데이터를 useState를 사용하여 변수에 저장합니다.
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const componentRef = useRef();

  useEffect(() => {
    getReciepts();
  }, []);

  useEffect(() => {
    if (data) {
    }
  }, [data]);


  if (!auth.isAuthenticated) return <Redirect to="/" />;

  const handleItemSelect = (e, id) => {
    if (e.target.checked) {
      setSelectedItems([...selectedItems, id]);
    } else {
      setSelectedItems(selectedItems.filter(itemId => itemId !== id));
    }
  };

  const handleBulkAction = () => {
    putReciepts({
      ids: selectedItems,
      processType: selectedOption,
    })
  };

  const handleItemDelete = () => {
    deleteReciept(deletedId);
    window.location.reload();
  };

  const handleItemDuplicate = async (e) => {
    e.preventDefault(); // 기본 동작 취소
    await duplicateReciept(duplicatedId);
    await getReciepts(searchQuery);
    setShowModal(false);
    alert("복사가 완료됐습니다.");
  };

  const handleEdit = (id) => {
    history.push(`/reciept-update/${id}`);
  };

  const handleSearch = (e) => {
    e.preventDefault(); // 기본 동작 취소
    getReciepts(searchQuery);
  };

  const handleModal = (id) => {
    setDuplicatedId(id);
    setShowModal(true);
  };

  const handleModal2 = (id) => {
    setDeletedId(id);
    setShowModal2(true);
  };

  const handleModal3 = (id) => {
    fetchData(id);
    setShowModal3(true);
  };

  const handleCancel = () => {
    setShowModal(false);
    setShowModal2(false);
    setShowModal3(false);
  };

  const LinktoRegister = () => {
    history.push(`/register-reciepts`);
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  return (
    <Layout>
      <br />
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <Form inline className="mr-2" onSubmit={handleSearch}>
            <Form.Control
              size="sm"
              type="text"
              placeholder="검색어 입력"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Form>
          <Button size="sm" variant="outline-primary" type="submit" className="mx-2">
            검색
          </Button>
        </div>
        {auth.isAuthenticated && auth.me.role == "OUTSIDER" ? (
          <>
          </>
        ) : (
          <div className="d-flex align-items-center">
            <Form inline className="mr-2">
              <Form.Select size="sm" as="select" value={selectedOption} onChange={handleOptionChange}>
                <option value="">공정 선택</option>
                <option value="접수">접수</option>
                <option value="인쇄">인쇄</option>
                <option value="재단">재단</option>
                <option value="접지">접지</option>
                <option value="노리">노리</option>
                <option value="정합">정합</option>
                <option value="사철">사철</option>
                <option value="가다미">가다미</option>
                <option value="삼면재단">삼면재단</option>
                <option value="후가공">후가공</option>
                <option value="성책">성책</option>
                <option value="포장">포장</option>
                <option value="제작완료">제작완료</option>
                <option value="납품완료">납품완료</option>
              </Form.Select>
            </Form>
            <Button size="sm" variant="primary" onClick={() => handleBulkAction(selectedOption)} disabled={selectedItems.length === 0} className="mx-2">
              일괄 처리
            </Button>
            <Button size="sm" variant="secondary" onClick={LinktoRegister}>
              접수 등록
            </Button>
            <ReactToPrint
              trigger={() => (
                <Button size="sm" className="mx-2" variant="secondary">
                  프린트하기
                </Button>
              )}
              content={() => componentRef.current}
            />
          </div>
        )}
      </div>
      <br />
      <div>
        <div className="table-responsive">
          <Table bordered hover>
            <thead className="table-active">
              <tr>
                <th></th>
                <th>ID</th>
                <th>거래처</th>
                <th>제품명</th>
                <th>수량</th>
                <th>절수(규격)</th>
                <th>페이지</th>
                <th>제본</th>
                <th>납기일</th>
                <th>현재 단계</th>
                <th>기능</th>
              </tr>
            </thead>
            <tbody>
              {reciepts.slice((currentPage - 1) * 20, (currentPage - 1) * 20 + 20).map((r, i) => {
                const isChecked = selectedItems.includes(r.id);
                if (auth.isAuthenticated && auth.me.role == "OUTSIDER" && auth.me.outsider != r.account) {
                  return <></>;
                }
                return (
                  <tr key={r.id} className="inner-box">
                    <td><input type="checkbox" checked={isChecked} onChange={e => handleItemSelect(e, r.id)} /></td>
                    <td>
                      <a href={`/reciepts/${r.id}`} onClick={(e) => {
                        e.preventDefault(); // 기본 링크 이벤트를 막음
                        window.open(`/reciepts/${r.id}`, '_blank', 'width=800,height=600'); // 새 창 열기
                      }}>
                        {r.barcode}
                      </a>
                    </td>
                    <td>
                      {r.account}
                    </td>
                    <td>
                      {r.productName}
                    </td>
                    <td>
                      {r.quantity}
                    </td>
                    <td>
                      {r.bowNumber}({r.standard})
                    </td>
                    <td>
                      {r.totalPage}p
                    </td>
                    <td>
                      {r.bindingMethod}
                    </td>
                    <td>
                      {r.dueDate?.substring(0, 10)}
                    </td>
                    <td>
                      {r.processType[r.currentProcess]}
                    </td>
                    <td>
                      {auth.isAuthenticated && auth.me.role == "OUTSIDER" ? (
                        <>
                        </>
                      ) : (
                        <div className="button-group">
                          <Button className="mr-sm-2 btn btn-secondary" size="sm" onClick={() => handleEdit(r.id)}>
                            수정
                          </Button>
                          <Button className="mr-sm-2 btn btn-primary" size="sm" onClick={() => handleModal(r.id)}>
                            복사
                          </Button>
                          <Button className="mr-sm-2 btn btn-danger" size="sm" onClick={() => handleModal2(r.id)}>
                            삭제
                          </Button>
                          <Button className="mr-sm-2 btn btn-secondary" size="sm" onClick={() => handleModal3(r.id)}>
                            H
                          </Button>
                        </div>
                      )}

                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <Pagination>
          {pageNumbers.map((number) => (
            <Pagination.Item key={number} active={number === currentPage} onClick={() => setCurrentPage(number)}>
              {number}
            </Pagination.Item>
          ))}
        </Pagination>
      </div>
      {showModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            <div style={{ marginBottom: "20px" }}>
              복사하시겠습니까?
            </div>
            <Form inline className="mr-2" onSubmit={handleItemDuplicate}>
              <Button size="sm" variant="outline-primary" type="submit" className="mx-2">
                확인
              </Button>
              <Button size="sm" variant="outline-secondary" onClick={handleCancel}>
                취소
              </Button>
            </Form>
          </div>
        </div>
      )}

      {showModal2 && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            <div style={{ marginBottom: "20px" }}>
              삭제하시겠습니까??
            </div>
            <Form inline className="mr-2" onSubmit={handleItemDelete}>
              <Button size="sm" variant="outline-primary" type="submit" className="mx-2">
                확인
              </Button>
              <Button size="sm" variant="outline-secondary" onClick={handleCancel}>
                취소
              </Button>
            </Form>
          </div>
        </div>
      )}

      {showModal3 && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              padding: "30px",
              borderRadius: "10px",
              width: "500px",
            }}
          >
            {!data || !data?.histories || !data?.histories?.length == 0 ? (
              <>
                <div className="row">
                  <div className="col-3 border" style={{ fontSize: "12px", fontWeight: "bold" }}>유저명</div>
                  <div className="col-4 border" style={{ fontSize: "12px", fontWeight: "bold" }}>처리일시</div>
                  <div className="col border" style={{ fontSize: "12px", fontWeight: "bold" }}>이전</div>
                  <div className="col border" style={{ fontSize: "12px", fontWeight: "bold" }}>변경</div>
                </div>
              </>
            ) : (
              <div>
                변경내역이 없습니다.
              </div>
            )}
            <div>
              {data?.histories?.map((c, index) => {
                var date = new Date(c.createdAt);

                // KST 시간대 오프셋 (UTC+9)
                var kstOffset = 9 * 60;

                // KST 시간 계산
                var kstTime = new Date(date.getTime() + kstOffset * 60 * 1000);

                // KST 시간을 원하는 형식으로 표시 (yyyy-MM-dd HH:mm)
                var kstTimeString = kstTime.toISOString().replace('T', ' ').slice(0, 16);

                return <div className="row">
                  <div className="col-3 border" style={{ fontSize: "12px" }}>{c.user.username}</div>
                  <div className="col-4 border" style={{ fontSize: "12px" }}>{kstTimeString}</div>
                  <div className="col border" style={{ fontSize: "12px" }}>{c.previousProcessType}</div>
                  <div className="col border" style={{ fontSize: "12px" }}>{c.processType}</div>
                </div>;
              })}
            </div>
            <br />
            <Button size="sm" variant="outline-secondary" onClick={handleCancel}>
              닫기
            </Button>
          </div>
        </div>
      )}

      <div style={{ display: "none" }}>
        <div ref={componentRef}>
          <div className="table-responsive" style={{ wordWrap: "nowrap", fontSize: "10px", display: "block", height: "1080px", lineHeight: "130%", width: "735px", backgroundColor: "white", textAlign: "center" }}>
            <Table bordered hover>
              <thead className="table-active">
                <tr>
                  <th>ID</th>
                  <th>거래처</th>
                  <th>제품명</th>
                  <th>수량</th>
                  <th>절수(규격)</th>
                  <th>페이지</th>
                  <th>제본</th>
                  <th>납기일</th>
                  <th>현재 단계</th>
                </tr>
              </thead>
              <tbody>
                {reciepts
                  .filter(r => r.currentProcessKr !== '납품완료')
                  .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate))
                  .map((r, i) => {
                    return (
                      <tr key={r.id} className="inner-box">
                        <td>
                          <Link to={`/reciepts/${r.id}`}>
                            {r.barcode}
                          </Link>
                        </td>
                        <td>
                          {r.account}
                        </td>
                        <td>
                          {r.productName}
                        </td>
                        <td>
                          {r.quantity}
                        </td>
                        <td>
                          {r.bowNumber}({r.standard})
                        </td>
                        <td>
                          {r.totalPage}p
                        </td>
                        <td>
                          {r.bindingMethod}
                        </td>
                        <td>
                          {r.dueDate?.substring(5, 10)}
                        </td>
                        <td>
                          {r.processType[r.currentProcess]}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  reciepts: state.reciepts,
  reciept: state.reciept
});

export default compose(requireAuth, connect(mapStateToProps, { getReciepts, duplicateReciept, deleteReciept, putReciepts }))(Reciepts);
