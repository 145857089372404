import React, { useEffect, useState } from 'react';
import { getStatistics, getRecieptsByProcessType, putReciepts } from '../../store/actions/receiptsActions';
import { putReciept } from '../../store/actions/receiptActions';
import { Redirect } from 'react-router-dom';

import { compose } from 'redux';
import { connect } from 'react-redux';
import Layout from '../../layout/Layout';
import _ from 'lodash';
import requireAuth from '../../hoc/requireAuth';
import { Nav, NavItem, Card, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

const ProcessControl = ({ auth, getStatistics, putReciepts, putReciept, getRecieptsByProcessType, reciepts: { reciepts, statistics, isLoading, error }, history }) => {
    useEffect(() => {
        if (auth.me.role == "OUTSIDER") {
            getStatistics(auth.me.outsider);
        } else {
            getStatistics("");
        }
    }, []);

    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedOption, setSelectedOption] = useState("");
    const [inputValue, setInputValue] = useState("");
    const [selectedCard, setSelectedCard] = useState("");
    const [selectedAb, setSelectedAb] = useState("페이지");


    const handleCardClick = (title) => {
        getRecieptsByProcessType(title);
        setSelectedCard(title);
        if (title != "인쇄") {
            setSelectedAb("페이지")
        } else {
            setSelectedAb("인쇄소")
        }
    };

    if (!auth.isAuthenticated) return <Redirect to="/" />;

    const handleItemSelect = (e, id) => {
        if (e.target.checked) {
            setSelectedItems([...selectedItems, id]);
        } else {
            setSelectedItems(selectedItems.filter(itemId => itemId !== id));
        }
    };

    const handleSubmit = async () => {
        await putReciept(inputValue, history);
        if (auth.me.role == "OUTSIDER") {
            await getStatistics(auth.me.outsider);
        } else {
            await getStatistics("");
        }
        console.log(selectedCard)
        getRecieptsByProcessType(selectedCard);
    };

    const handlePutReciept = async (id, history) => {
        await putReciept(id, history);
        if (auth.me.role == "OUTSIDER") {
            await getStatistics(auth.me.outsider);
        } else {
            await getStatistics("");
        }
        console.log(selectedCard)
        getRecieptsByProcessType(selectedCard);
    };

    const handleBulkAction = () => {
        putReciepts({
            ids: selectedItems,
            processType: selectedOption,
        })
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const LinktoRegister = () => {
        history.push(`/register-reciepts`);
    };

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    if (!auth.isAuthenticated) return <Redirect to="/" />;
    const cards = ["접수", "인쇄", "재단", "접지", "노리", "정합", "사철", "가다미", "삼면재단", "후가공", "성책", "포장", "제작완료", "납품완료"];

    if (auth.isAuthenticated && auth.me.role == "OUTSIDER") {
        return (
            <Layout>
                <div class="event-schedule-area-two bg-color pad100">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <br />
                                <div className="container-fluid">
                                    <div className="row row-cols row-cols-md-8 g-4">
                                        {statistics[0] && statistics[0].processType.map((s, i) => (
                                            <div key={i} className="col mb-4">
                                                <Card style={{ width: "120%", }} onClick={() => handleCardClick(cards[i])}>
                                                    <Card.Body>
                                                        <Card.Title className='text-center' style={{ fontSize: "16px", fontWeight: "bold" }}>{cards[i]}</Card.Title>
                                                        <Card.Text className='text-center' style={{ fontSize: "20px" }}>{s}</Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <Table bordered hover>
                                        <thead className="table-active">
                                            <tr>
                                                <th>거래처</th>
                                                <th>제품명</th>
                                                <th>수량</th>
                                                <th>절수(규격)</th>
                                                <th>{selectedAb}</th>
                                                <th>납기일</th>
                                                <th>현재 단계</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {reciepts.map((r, i) => {
                                                const isChecked = selectedItems.includes(r.id);
                                                if (auth.isAuthenticated && auth.me.role == "OUTSIDER" && auth.me.outsider != r.account) {
                                                    return <></>;
                                                }
                                                return (
                                                    <tr key={r.id} className="inner-box">
                                                        <td>
                                                            {r.account}
                                                        </td>
                                                        <td>
                                                            {r.productName}
                                                        </td>
                                                        <td>
                                                            {r.quantity}
                                                        </td>
                                                        <td>
                                                            {r.bowNumber}({r.standard})
                                                        </td>
                                                        <td>
                                                            {selectedAb == "인쇄소" ? (<th>{r.printingPress}</th>) : (<th>{r.totalPage}</th>)}
                                                        </td>
                                                        <td>
                                                            {r.dueDate?.substring(0, 10)}
                                                        </td>
                                                        <td>
                                                            {r.processType[r.currentProcess]}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }

    return (
        <Layout>
            <div class="event-schedule-area-two bg-color pad100">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <br />
                            <div className="container-fluid">
                                <div className="row row-cols row-cols-md-8 g-4">
                                    {statistics[0] && statistics[0].processType.map((s, i) => (
                                        <div key={i} className="col mb-4">
                                            <Card style={{ width: "120%", }} onClick={() => handleCardClick(cards[i])}>
                                                <Card.Body>
                                                    <Card.Title className='text-center' style={{ fontSize: "16px", fontWeight: "bold" }}>{cards[i]}</Card.Title>
                                                    <Card.Text className='text-center' style={{ fontSize: "20px" }}>{s}</Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                    <Form onSubmit={handleSubmit}>
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" placeholder="바코드" aria-label="바코드" aria-describedby="basic-addon2" value={inputValue} onChange={handleInputChange} />
                                            <div className="input-group-append">
                                                <button className="btn btn-outline-secondary" type="submit" style={{ marginTop: "5px", marginLeft: "5px" }}>공정 넘기기</button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                                <div className="d-flex align-items-center">
                                    <Form inline className="mr-2">
                                        <Form.Select size="sm" as="select" value={selectedOption} onChange={handleOptionChange}>
                                            <option value="">공정 선택</option>
                                            <option value="접수">접수</option>
                                            <option value="인쇄">인쇄</option>
                                            <option value="재단">재단</option>
                                            <option value="접지">접지</option>
                                            <option value="노리">노리</option>
                                            <option value="정합">정합</option>
                                            <option value="사철">사철</option>
                                            <option value="가다미">가다미</option>
                                            <option value="삼면재단">삼면재단</option>
                                            <option value="성책">성책</option>
                                            <option value="포장">포장</option>
                                            <option value="제작완료">제작완료</option>
                                            <option value="납품완료">납품완료</option>
                                        </Form.Select>
                                    </Form>
                                    <Button size="sm" variant="primary" onClick={() => handleBulkAction(selectedOption)} disabled={selectedItems.length === 0} className="mx-2">
                                        일괄 처리
                                    </Button>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <Table bordered hover>
                                    <thead className="table-active">
                                        <tr>
                                            <th></th>
                                            <th>ID</th>
                                            <th>거래처</th>
                                            <th>제품명</th>
                                            <th>수량</th>
                                            <th>절수(규격)</th>
                                            <th>{selectedAb}</th>
                                            <th>납기일</th>
                                            <th>현재 단계</th>
                                            <th>기능</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {reciepts.map((r, i) => {
                                            const isChecked = selectedItems.includes(r.id);
                                            return (
                                                <tr key={r.id} className="inner-box">
                                                    <td><input type="checkbox" checked={isChecked} onChange={e => handleItemSelect(e, r.id)} /></td>
                                                    <td>
                                                        <a href={`/reciepts/${r.id}`} onClick={(e) => {
                                                            e.preventDefault(); // 기본 링크 이벤트를 막음
                                                            window.open(`/reciepts/${r.id}`, '_blank', 'width=800,height=600'); // 새 창 열기
                                                        }}>
                                                            {r.barcode}
                                                        </a>
                                                    </td>
                                                    <td>
                                                        {r.account}
                                                    </td>
                                                    <td>
                                                        {r.productName}
                                                    </td>
                                                    <td>
                                                        {r.quantity}
                                                    </td>
                                                    <td>
                                                        {r.bowNumber}({r.standard})
                                                    </td>
                                                    <td>
                                                        {selectedAb == "인쇄소" ? (<th>{r.printingPress}</th>) : (<th>{r.totalPage}</th>)}
                                                    </td>
                                                    <td>
                                                        {r.dueDate?.substring(0, 10)}
                                                    </td>
                                                    <td>
                                                        {r.processType[r.currentProcess]}
                                                    </td>
                                                    <td>
                                                        <button
                                                            onClick={() => handlePutReciept(r.barcode, history)}
                                                            type="button"
                                                            className="btn btn-outline-primary"
                                                        >
                                                            공정 넘기기
                                                        </button>

                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    reciepts: state.reciepts,
    statistics: state.statistics,
});

export default compose(requireAuth, connect(mapStateToProps, { getStatistics, putReciept, putReciepts, getRecieptsByProcessType }))(ProcessControl);
