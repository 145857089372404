import React, { useEffect, useRef } from 'react';
import { getReciept } from '../../store/actions/receiptActions';
import { Redirect } from 'react-router-dom';

import { compose } from 'redux';
import { connect } from 'react-redux';
import Layout from '../../layout/Layout';
import _ from 'lodash';
import requireAuth from '../../hoc/requireAuth';
import './styles.css';
import ReactToPrint from "react-to-print";
import { Button } from 'react-bootstrap';

const Reciept = ({ auth, getReciept, reciept: { reciept, isLoading, error }, history, match }) => {
  const matchID = match.params.id;
  const componentRef = useRef();
  useEffect(() => {
    getReciept(matchID, history);
  }, [matchID]);

  if (!auth.isAuthenticated) return <Redirect to="/" />;

  const handleEdit = (id) => {
    history.push(`/reciept-update/${id}`);
  };

  return (
    <Layout>
      <br />
      <br />
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <Button className="mr-sm-2 btn" size="sm" onClick={() => handleEdit(reciept.id)}>
            수정
          </Button>
        </div>
      </div>
      <br />
      <div className="div_container" style={{ whiteSpace: "nowrap" }}>
        <div ref={componentRef}>
          {/* 첫번째 */}
          <div className='div_paper' style={{ whiteSpace: "nowrap" }}>
            <div className="container w-auto h-auto" style={{ lineHeight: "220%", fontSize: "14px" }}>
              <div className="row">
                <div className="col-8" style={{ marginTop: "50px" }}>
                  <p className="fs-2 fw-bolder">작업지시서 ({reciept.barcode})</p>
                </div>
                <div className="col-3" style={{ marginTop: "30px" }}>
                  <img src={`https://bwipjs-api.metafloor.com/?bcid=code128&text=${reciept.barcode}&scale=4&includetext&backgroundcolor=ffffff`} style={{ width: "130px", height: "80px" }} />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col">
                  <div className="row">
                    <div className="col-2 border bg-light text-center" style={{ fontSize: "13px" }}>
                      고객명
                    </div>
                    <div className="col border" style={{ fontSize: "20px", fontWeight: "bolder" }}>
                      {reciept.account}
                    </div>
                    <div className="col-3 border">
                      (접){reciept.manager}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-2 border bg-light text-center" style={{ fontSize: "13px" }}>
                      연락처
                    </div>
                    <div className="col border">
                      {reciept.customerName}({reciept.customerContact})
                    </div>
                    <div className="col-3 border">
                      (접){reciept.createdAt?.substring(2, 10)}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-2 border bg-light text-center" style={{ fontSize: "13px" }}>
                      납기일
                    </div>
                    <div className="col border fw-bolder fs-5">
                      {reciept.dueDate?.substring(0, 10)}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-1 border bg-light text-center">
                      제본
                    </div>
                    <div className="col border">
                      {reciept.bookbindery}
                    </div>
                    <div className="col-1 border bg-light text-center">
                      인쇄
                    </div>
                    <div className="col border">
                      {reciept.printingPress}
                    </div>
                    <div className="col-1 border bg-light text-center">
                      표지
                    </div>
                    <div className="col border">
                      {reciept.coverSource}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row"  style={{ marginTop: "15px" }}>
                <div className="col">
                  <div className="row">
                    <div className="col-4 border bg-light text-center">
                      제품명
                    </div>
                    <div className="col border bg-light text-center">
                      절수(규격)
                    </div>
                    <div className="col border bg-light text-center">
                      페이지
                    </div>
                    <div className="col border bg-light text-center">
                      수량
                    </div>
                  </div>
                  <div className="row" style={{ fontSize: "15px", fontWeight: "bolder" }}>
                    <div className="col-4 border text-center">
                      {reciept.productName}
                    </div>
                    <div className="col border text-center">
                      {reciept.bowNumber}({reciept.standard})
                    </div>
                    <div className="col border text-center">
                      {reciept.totalPage}
                    </div>
                    <div className="col border text-center">
                      {reciept.quantity}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginTop: "15px" }}>
                <div className="col">
                  <div className="row">
                    <div className="col border bg-light" style={{ fontSize: "18px", marginBottom: "5px" }}>용지사양</div>
                  </div>
                  <div className="row">
                    <div className="col border" style={{ fontSize: "15px" }}>구분</div>
                    <div className="col-1 border" style={{ fontSize: "15px", width: "80px" }}>평량</div>
                    <div className="col border" style={{ fontSize: "15px" }}>지종</div>
                    <div className="col-1 border" style={{ fontSize: "15px", width: "80px" }}>페이지</div>
                    <div className="col-1 border" style={{ fontSize: "15px", width: "80px"}}>인쇄</div>
                    <div className="col border" style={{ fontSize: "15px" }}>비고</div>
                  </div>
                  {reciept.composition?.map((c, index) => {
                    return <div className="row">
                      <div className="col border" style={{ fontSize: "12px" }}>{c.description}</div>
                      <div className="col-1 border" style={{ fontSize: "12px", width: "80px" }}>{c.quantity}g</div>
                      <div className="col border" style={{ fontSize: "12px" }}>{c.paper}</div>
                      <div className="col-1 border" style={{ fontSize: "12px", width: "80px" }}>{c.page}p</div>
                      <div className="col-1 border" style={{ fontSize: "12px" , width: "80px"}}>{c.insa}</div>
                      <div className="col border" style={{ fontSize: "12px" }}>{c.memo}</div>
                    </div>;
                  })}
                  <div className="row">
                    <div className="col border" style={{ fontSize: "15px", fontWeight: "bold" }}>{reciept.totalComposition}</div>
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginTop: "15px" }}>
                <div className="col-md-5" style={{ marginRight: "25px" }}>
                  <div className="row" style={{ height: "50px" }}>
                    <div className="col border bg-light text-center" style={{ fontSize: "18px", marginBottom: "10px" }}>제본사양</div>
                  </div>
                  <div className="row" style={{ height: "50px" }}>
                    <div className="col-3 border bg-light text-center" style={{ fontSize: "15px", display: "flex", alignItems: "center", justifyContent: "center" }}>제본</div>
                    <div className="col border" style={{ fontSize: "15px", display: "flex", alignItems: "center", justifyContent: "center" }}>{reciept.bindingMethod}</div>
                  </div>
                  <div className="row" style={{ height: "50px" }}>
                    <div className="col-3 border bg-light text-center" style={{ fontSize: "15px", display: "flex", alignItems: "center", justifyContent: "center" }}>금장</div>
                    <div className="col border" style={{ fontSize: "15px", display: "flex", alignItems: "center", justifyContent: "center" }}>{reciept.gildEdge}</div>
                  </div>
                  <div className="row" style={{ height: "50px" }}>
                    <div className="col-3 border bg-light text-center" style={{ fontSize: "15px", display: "flex", alignItems: "center", justifyContent: "center" }}>귀도리</div>
                    <div className="col border" style={{ fontSize: "15px", display: "flex", alignItems: "center", justifyContent: "center" }}>{reciept.gwidori}</div>
                  </div>
                  <div className="row" style={{ height: "50px" }}>
                    <div className="col-3 border bg-light text-center" style={{ fontSize: "15px", display: "flex", alignItems: "center", justifyContent: "center" }}>헤드밴드</div>
                    <div className="col border" style={{ fontSize: "15px", display: "flex", alignItems: "center", justifyContent: "center" }}>{reciept.headBand}</div>
                  </div>
                  <div className="row" style={{ height: "50px" }}>
                    <div className="col-3 border bg-light text-center" style={{ fontSize: "15px", display: "flex", alignItems: "center", justifyContent: "center" }}>시오리</div>
                    <div className="col border" style={{ fontSize: "15px", display: "flex", alignItems: "center", justifyContent: "center" }}>{reciept.ribbon}</div>
                  </div>
                  <div className="row" style={{ height: "50px" }}>
                    <div className="col-3 border bg-light text-center" style={{ fontSize: "15px", display: "flex", alignItems: "center", justifyContent: "center" }}>포장</div>
                    <div className="col border" style={{ fontSize: "15px", display: "flex", alignItems: "center", justifyContent: "center" }}>{reciept.shrinkWrap}</div>
                  </div>
                </div>
                <div className="col" style={{ width: "200px" }}>
                  <div className="row border bg-light" style={{ marginBottom: "10px" }}>
                    <div className="col text-center"> 특이사항</div>
                  </div>
                  <div className="row" style={{ fontSize: "14px", height: "50px" }}>
                    <div className="col border" style={{ whiteSpace: "normal", wordWrap: "break-word", height: "100%", display: "flex", alignItems: "center", textAlign: "left" }}>
                      {reciept.memo1}
                    </div>
                  </div>
                  <div className="row" style={{ fontSize: "14px", height: "50px" }}>
                    <div className="col border" style={{ whiteSpace: "normal", wordWrap: "break-word", height: "100%", display: "flex", alignItems: "center", textAlign: "left" }}>
                      {reciept.memo2}
                    </div>
                  </div>
                  <div className="row" style={{ fontSize: "14px", height: "50px" }}>
                    <div className="col border" style={{ whiteSpace: "normal", wordWrap: "break-word", height: "100%", display: "flex", alignItems: "center", textAlign: "left" }}>
                      {reciept.memo3}
                    </div>
                  </div>
                  <div className="row" style={{ fontSize: "14px", height: "50px" }}>
                    <div className="col border" style={{ whiteSpace: "normal", wordWrap: "break-word", height: "100%", display: "flex", alignItems: "center", textAlign: "left" }}>
                      {reciept.memo4}
                    </div>
                  </div>
                  <div className="row" style={{ fontSize: "14px", height: "50px" }}>
                    <div className="col border" style={{ whiteSpace: "normal", wordWrap: "break-word", height: "100%", display: "flex", alignItems: "center", textAlign: "left" }}>
                      {reciept.memo5}
                    </div>
                  </div>
                  <div className="row" style={{ fontSize: "14px", height: "50px" }}>
                    <div className="col border" style={{ whiteSpace: "normal", wordWrap: "break-word", height: "100%", display: "flex", alignItems: "center", textAlign: "left" }}>
                      {reciept.memo6}
                    </div>
                  </div>
                  <div className="row" style={{ fontSize: "14px", height: "50px" }}>
                    <div className="col border" style={{ whiteSpace: "normal", wordWrap: "break-word", height: "100%", display: "flex", alignItems: "center", textAlign: "left" }}>
                      {reciept.memo7}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* 두번째 */}
          <div className='div_paper' ref={componentRef}>
            <div className="container w-auto h-auto" style={{ lineHeight: "220%", fontSize: "14px" }}>
              <div className="row">
                <div className="col-2 border" style={{ marginTop: "50px", height: "130px" }}>
                  <div className="d-flex justify-content-center align-items-center fw-bold fs-5 h-100">{reciept.barcode}</div>
                </div>
                <div className="col border" style={{ marginTop: "50px", height: "130px" }}>
                  <div className="row">
                    <div className="col border">
                      <div className="row border-bottom bg-light">
                        <div className="col text-center fw-bold">고객명</div>
                      </div>
                      <div className="row">
                        <div className="col text-center" style={{ whiteSpace: "nowrap" }}>{reciept.account}</div>
                      </div>
                    </div>
                    <div className="col border">
                      <div className="row border-bottom bg-light">
                        <div className="col text-center fw-bold">제품명</div>
                      </div>
                      <div className="row">
                        <div className="col text-center" style={{ whiteSpace: "nowrap" }}>{reciept.productName}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col border">
                      <div className="row border-bottom bg-light">
                        <div className="col text-center fw-bold">절수(규격)</div>
                      </div>
                      <div className="row">
                        <div className="col text-center" style={{ whiteSpace: "nowrap" }}>{reciept.bowNumber}({reciept.standard})</div>
                      </div>
                    </div>
                    <div className="col border">
                      <div className="row border-bottom bg-light">
                        <div className="col text-center fw-bold">페이지</div>
                      </div>
                      <div className="row">
                        <div className="col text-center" style={{ whiteSpace: "nowrap" }}>{reciept.totalPage}</div>
                      </div>
                    </div>
                    <div className="col border">
                      <div className="row border-bottom bg-light">
                        <div className="col text-center fw-bold">수량</div>
                      </div>
                      <div className="row">
                        <div className="col text-center" style={{ whiteSpace: "nowrap" }}>{reciept.quantity}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col">
                  <div className="row">
                    <div className="col-1 border bg-light text-center" style={{ fontSize: "5px" }}>NO</div>
                    <div className="col-2 border bg-light" style={{ fontSize: "15px" }}>제작공정</div>
                    <div className="col border bg-light" style={{ fontSize: "15px" }}>수량</div>
                  </div>
                  {reciept.processType?.map((m, index) => {
                    if (index == reciept.processType?.length - 1 || index == reciept.processType?.length - 2) {
                      return <></>;
                    }
                    if (m == "접지") {
                      return <div className="row" style={{}}>
                        <div className="col-1 border text-center" style={{ fontSize: "5px", height: "150px", display: "flex", alignItems: "center", justifyContent: "center" }}>{index + 1}</div>
                        <div className="col-2 border" style={{ fontSize: "15px", height: "150px", display: "flex", alignItems: "center", justifyContent: "center" }}>{m}</div>
                        <div className="col" style={{ fontSize: "15px" }}>
                          <div className='row border' style={{ fontSize: "12px", display: "flex", alignItems: "center", justifyContent: "center", height: "50px" }}>
                            {reciept.processTypeMemo?.map((m, i) => {
                              if (m.processTypeIndex == index) {
                                return <>{m.memo}</>;
                              }
                              return <></>;
                            })}
                          </div>
                          <div className='row border' style={{ fontSize: "12px", display: "flex", alignItems: "center", justifyContent: "center", height: "50px" }}></div>
                          <div className='row border' style={{ fontSize: "12px", display: "flex", alignItems: "center", justifyContent: "center", height: "50px" }}></div>
                        </div>
                      </div>;
                    }
                    if (m == "노리") {
                      return <div className="row">
                        <div className="col-1 border text-center" style={{ fontSize: "5px", height: "100px", display: "flex", alignItems: "center", justifyContent: "center" }}>{index + 1}</div>
                        <div className="col-2 border" style={{ fontSize: "15px", height: "100px", display: "flex", alignItems: "center", justifyContent: "center" }}>{m}</div>
                        <div className="col" style={{ fontSize: "15px" }}>
                          <div className='row border' style={{ fontSize: "12px", display: "flex", alignItems: "center", justifyContent: "center", height: "50px" }}>
                            {reciept.processTypeMemo?.map((m, i) => {
                              if (m.processTypeIndex == index) {
                                return <>{m.memo}</>;
                              }
                              return <></>;
                            })}
                          </div>
                          <div className='row border' style={{ fontSize: "12px", display: "flex", alignItems: "center", justifyContent: "center", height: "50px" }}></div>
                        </div>
                      </div>;
                    }
                    return <div className="row" style={{ height: "50px" }}>
                      <div className="col-1 border text-center" style={{ fontSize: "5px", display: "flex", alignItems: "center", justifyContent: "center" }}>{index + 1}</div>
                      <div className="col-2 border" style={{ fontSize: "15px", display: "flex", alignItems: "center", justifyContent: "center" }}>{m}</div>
                      <div className="col border" style={{ fontSize: "12px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {reciept.processTypeMemo?.map((m, i) => {
                          if (m.processTypeIndex == index) {
                            return <>{m.memo}</>;
                          }
                          return <></>;
                        })}
                      </div>
                    </div>;
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ReactToPrint
          trigger={() => <button>프린트 하기</button>}
          content={() => componentRef.current}
        />
      </div>
      <br /><br />
    </Layout>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    reciept: state.reciept,
  };
}


export default compose(requireAuth, connect(mapStateToProps, { getReciept }))(Reciept);
