import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getUsers } from '../../store/actions/usersActions';
import Layout from '../../layout/Layout';
import requireAuth from '../../hoc/requireAuth';
import { Table, Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { deleteUser } from '../../store/actions/userActions';

import './styles.css';

const Users = ({ getUsers,deleteUser, users: { users, isLoading }, history }) => {
  const [showModal, setShowModal] = useState(false);
  const [deletedId, setDeletedId] = useState(false);
  useEffect(() => {
    getUsers();
  }, []);

  const LinktoRegister = () => {
    history.push(`/register`);
  };

  const handleEdit = (id) => {
    history.push(`/user-update/${id}`);
  };

  const handleItemDelete = async () => {
    deleteUser(deletedId, history)
  };

  const handleModal1 = (id) => {
    setDeletedId(id);
    setShowModal(true);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const [showNavBar, setShowNavBar] = useState(false);
  return (
    <Layout>
      <br />
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
        </div>
        <div className="d-flex align-items-center">
          <Button size="sm" variant="secondary" onClick={LinktoRegister}>
            유저 등록
          </Button>
        </div>
      </div>
      <br />
      <div className="table-responsive">
        <Table bordered hover>
          <thead className="table-active">
            <tr>
              <th>이름</th>
              <th>ID</th>
              <th>역할</th>
              <th>거래처명</th>
              <th>기능</th>
            </tr>
          </thead>
          <tbody>
            {users.map((u, i) => {
              return (
                <tr key={u.id} className="inner-box">
                  <td>
                    {u.username}
                  </td>
                  <td>
                    {u.email}
                  </td>
                  <td>
                    {u.role == "OUTSIDER" ? (
                      <>
                        거래처용
                      </>
                    ) : (
                      <>{u.role}</>
                    )}
                  </td>
                  <td>
                    {u.outsider}
                  </td>
                  <td>
                    <div className="button-group">
                      <Button className="mr-sm-2 btn btn-secondary" size="sm" onClick={() => handleEdit(u.id)}>
                        수정
                      </Button>
                      <Button className="mr-sm-2 btn btn-danger" size="sm" onClick={() => handleModal1(u.id)}>
                        삭제
                      </Button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        {showModal && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "#fff",
                padding: "20px",
                borderRadius: "10px",
              }}
            >
              <div style={{ marginBottom: "20px" }}>
                삭제하시겠습니까??
              </div>
              <Form>
                <Button size="sm" variant="outline-primary" type="submit" className="mx-2" onClick={handleItemDelete}>
                  확인
                </Button>
                <Button size="sm" variant="outline-secondary" onClick={handleCancel}>
                  취소
                </Button>
                </Form>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  users: state.users,
});

export default compose(requireAuth, connect(mapStateToProps, { getUsers, deleteUser }))(Users);
