import * as Yup from 'yup';

export const registerSchema = Yup.object({
  username: Yup.string()
    .min(2, 'Must be 2 characters at minimum')
    .max(20, 'Must be 20 characters or less')
    .required('Required'),
  email: Yup.string().min(6, 'Must be 6 characters at minimum').max(40, 'Must be 20 characters or less').required('Required'),
  password: Yup.string()
    .min(4, 'Must be 6 characters at minimum')
    .max(20, 'Must be 20 characters or less')
    .required('Required'),
  role: Yup.string().required("role is required"),
});
