import React, { useState } from "react";
import Scanner from "../../components/Scanner/Scanner";
import { putReciept, getRecieptByBarcode } from "../../store/actions/receiptActions";
import { putReciepts } from "../../store/actions/receiptsActions";
import requireAuth from '../../hoc/requireAuth';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Layout from '../../layout/Layout';
import { Button } from "react-bootstrap";
import Form from 'react-bootstrap/Form';

const BarcodeScanner = ({ auth, getRecieptsByProcessType, getRecieptByBarcode, reciept: { reciept, isLoading, error }, putReciepts, history }) => {
  const [result, setResult] = useState(undefined);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [scanning, setScanning] = useState(false);
  const [selectedOption, setSelectedOption] = useState(undefined);
  const [xmrdltkgkd, setXmrdltkgkd] = useState("");
  const [isScanning, setIsScanning] = React.useState(false);

  const onResult = (res, code) => {
    setResult(code);
    if (code) {
      getRecieptByBarcode(code)
        .then((response) => {
          // 특정 API가 200일 때만 Quagga.stop()을 호출합니다.
          if (response.status === 200) {
            setIsScanning(true);
            setShowModal(true);
          } else {
            alert("없는 바코드입니다. 다시 시도해주세요.")
          }
        });
    }
  };

  const onModalConfirm = async () => {
    // API 호출
    putReciepts({
      ids: [reciept.id],
      processType: selectedOption,
      memo: xmrdltkgkd,
    })
    setShowModal2(false);
    alert("공정 넘기기 실행완료")
  };

  function processButton(op) {
    setSelectedOption(op);
    setShowModal(false);
    setShowModal2(true);
  }

  function createButtons(processType) {
    const buttons = [];

    for (let i = 0; i < processType?.length - 1; i++) {
      const buttonText = processType[i + 1];
      const buttonTitle = processType[i];
      const button = (
        <div>
          <Button variant="primary" onClick={() => processButton(buttonText)}>
            {buttonTitle}
          </Button><br /><br />
        </div>
      );
      buttons.push(button);
    }

    return buttons;
  }

  return (
    <Layout>
      <div>
        {!scanning ? (
          <div className="d-flex flex-column align-items-center justify-content-center">
            <h4 className="text-center fs-6">아래 버튼을 눌러 바코드를 스캔해주세요.</h4>
            <button className="text-center" onClick={() => setScanning(true)}>Start Scanning</button>
          </div>
        ) : (
          <div>
            <Scanner onResult={onResult} isScanning={isScanning} />
            {showModal && (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#fff",
                    padding: "20px",
                    borderRadius: "10px",
                  }}
                >
                  <div style={{ marginBottom: "20px" }}>
                    <h2>{result}</h2>
                  </div>
                  <p>완료된 공정을 선택해주세요.</p> <br />
                  {createButtons(reciept?.processType?.slice(reciept?.currentProcess, reciept?.processType?.length))}

                </div>
              </div>
            )}
            {showModal2 && (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#fff",
                    padding: "20px",
                    borderRadius: "10px",
                  }}
                >
                  <div style={{ marginBottom: "20px" }}>
                    <h2>{result}</h2>
                  </div>
                  <p>수량 및 특이사항을 입력해주세요.</p> <br />
                  <Form inline className="mr-2" onSubmit={onModalConfirm}>
                    <Form.Control
                      size="lg"
                      type="text"
                      placeholder="수량 및 특이사항"
                      value={xmrdltkgkd}
                      onChange={(e) => setXmrdltkgkd(e.target.value)}
                    />
                    <br/>
                    <Button size="sm" variant="outline-primary" type="submit" className="mx-2">
                      확인
                    </Button>
                  </Form>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  reciept: state.reciept,
});

export default compose(requireAuth, connect(mapStateToProps, { putReciepts, putReciept, getRecieptByBarcode }))(BarcodeScanner);
