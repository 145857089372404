import axios from 'axios';

import { attachTokenToHeaders } from './authActions';
import {
  GET_RECIEPTS_LOADING,
  GET_RECIEPTS_SUCCESS,
  GET_RECIEPTS_FAIL,
} from '../types';

export const getReciepts = (search) => async (dispatch, getState) => {
  dispatch({
    type: GET_RECIEPTS_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    let response;
    if (search) {
      response = await axios.get(`/api/reciepts?search=${search}`, options);
    } else {
      response = await axios.get('/api/reciepts', options);
    }
    dispatch({
      type: GET_RECIEPTS_SUCCESS,
      payload: { statistics: [], reciepts: response.data.reciepts },
    });
  } catch (err) {
    dispatch({
      type: GET_RECIEPTS_FAIL,
      payload: { error: err?.response?.data.reciepts || err.reciepts },
    });
  }
};

export const getRecieptsByProcessType = (processType) => async (dispatch, getState) => {
  dispatch({
    type: GET_RECIEPTS_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(`/api/reciepts/process-type?processType=${processType}`, options);

    dispatch({
      type: GET_RECIEPTS_SUCCESS,
      payload: { reciepts: response.data.reciepts },
    });
  } catch (err) {
    dispatch({
      type: GET_RECIEPTS_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const getStatistics = (account) => async (dispatch, getState) => {
  dispatch({
    type: GET_RECIEPTS_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(`/api/reciepts/statistics?account=${account}`, options);

    dispatch({
      type: GET_RECIEPTS_SUCCESS,
      payload: { statistics: response.data.statistics, reciepts: [] },
    });
  } catch (err) {
    dispatch({
      type: GET_RECIEPTS_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const putReciepts = (formData, history) => async (dispatch, getState) => {
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.post(`/api/reciepts/next-process`, formData,options);
    window.location.reload();
  } catch (err) {
    dispatch({
      type: GET_RECIEPTS_SUCCESS,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};
