import React from "react";
import { BrowserMultiFormatReader } from "@zxing/library";

const Scanner = ({ onResult }) => {
  const codeReader = new BrowserMultiFormatReader();

  const decodeOnce = (codeReader, selectedDeviceId) => {
    codeReader.decodeFromInputVideoDevice(selectedDeviceId, 'video').then(result => {
      onResult(result, result.getText());
    }).catch(err => {
      console.error(err);
    });
  };

  React.useEffect(() => {
    codeReader.listVideoInputDevices()
      .then((videoInputDevices) => {
        if (videoInputDevices.length > 2) {
          const selectedDeviceId = videoInputDevices[3].deviceId;
          decodeOnce(codeReader, selectedDeviceId);
        } else {
          const selectedDeviceId = videoInputDevices[0].deviceId;
          decodeOnce(codeReader, selectedDeviceId);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <div>
      <video id="video" width="100%" height="100%" muted></video>
    </div>
  );
};

export default Scanner;
