import React, { useState, useEffect } from 'react';
import { Link, withRouter, Redirect } from 'react-router-dom';

import { compose } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';

import { useFormik, Field, FieldArray, FormikProvider } from 'formik';
import DatePicker from "react-datepicker";
import { getUser, editUser } from '../../store/actions/userActions';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';

const PutUser = ({ auth, getUser, reciept: { reciept, isLoading, error }, history, editUser, match }) => {
    const [initialValues, setInitialValues] = useState({
        name: '',
        username: '',
        email: '',
        password: '',
        outsider: '',
        role: ''
    });

    const formik = useFormik({
        initialValues,
        onSubmit: (values) => {
            editUser(match.params.id, values, history);
            return <Redirect to="/users" />;
        },
    });

    useEffect(async () => {
        const matchID = match.params.id;
        const response = await axios.get(`https://sonomamg.co.kr/api/users/${matchID}`); // API를 호출합니다.

        formik.setValues({
            ...formik.values,
            name: response.data.user.name,
            username: response.data.user.username,
            email: response.data.user.email,
            outsider: response.data.user.outsider,
            role: response.data.user.role || []
        });
    }, [reciept]);

    return (
        <div className="register">
            <div className="container">
                <h1>회원 수정</h1>
                <p>
                    back to{' '}
                    <Link className="bold" to="/">
                        Home page
                    </Link>
                </p>
                <form onSubmit={formik.handleSubmit} noValidate>
                    <h2>Create new account</h2>
                    <div>
                        <input
                            placeholder="Username"
                            name="username"
                            className=""
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.username}
                        />
                        {formik.touched.username && formik.errors.username ? (
                            <p className="error">{formik.errors.username}</p>
                        ) : null}
                        <input
                            placeholder="ID"
                            name="email"
                            className=""
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <p className="error">{formik.errors.email}</p>
                        ) : null}
                        <input
                            placeholder="거래처명"
                            name="outsider"
                            className=""
                            type="outsider"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.outsider}
                        />
                        <select
                            name="role"
                            value={formik.values.role}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        >
                            <option value="">역할 선택</option>
                            <option value="USER">일반</option>
                            <option value="ADMIN">관리자</option>
                            <option value="OUTSIDER">거래처용</option>
                        </select>
                        {formik.values.role}
                    </div>
                    {error && <p className="error">{error}</p>}
                    <div>
                        <button className="btn submit" type="submit" disabled={isLoading || !formik.isValid}>
                            수정하기
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    reciept: state.reciept,
});

export default compose(withRouter, connect(mapStateToProps, { getUser, editUser }))(PutUser);
