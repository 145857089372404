
import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Users from '../../pages/Users/Users';
import { logOutUser } from '../../store/actions/authActions';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import './styles.css';

const AppNavbar = ({ auth, logOutUser, history }) => {
  const [expanded, setExpanded] = useState(false);

  const onLogOut = (event) => {
    event.preventDefault();
    logOutUser(history);
  };

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  if (auth.isAuthenticated && auth.me.role == "OUTSIDER") {
    return (
      <Navbar style={{ opacity: "0.8" }} fixed='top' bg="light" expand="xl">
        <Container>
          <Navbar.Brand href="#home" className="navbar-brand-custom fs-3" style={{ marginRight: '30px' }}>SONOMA</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle} />
          <Navbar.Collapse id="basic-navbar-nav" className={expanded ? 'show' : ''}>
            <Nav className="mr-auto">
              <Nav.Link href="/process-control" className="fs-5" style={{ marginRight: '15px' }}>공정관리</Nav.Link>
              {auth.isAuthenticated ? (
                <>
                  <NavDropdown title={auth.me.username} id="basic-nav-dropdown" className="fs-5" style={{ marginRight: '15px' }}>
                    <NavDropdown.Item href={`/${auth.me.username}`}>내 정보</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={onLogOut}>로그아웃</NavDropdown.Item>
                  </NavDropdown>
                </>
              ) : (
                <Nav.Link className="ml-auto fs-5" href="/login">Login</Nav.Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }

  return (
    <Navbar style={{ opacity: "0.8" }} fixed='top' bg="light" expand="xl">
      <Container>
        <Navbar.Brand href="#home" className="navbar-brand-custom fs-3" style={{ marginRight: '30px' }}>SONOMA</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle} />
        <Navbar.Collapse id="basic-navbar-nav" className={expanded ? 'show' : ''}>
          <Nav className="mr-auto">
            <Nav.Link href="/" className="fs-5" style={{ marginRight: '15px' }} >홈</Nav.Link>
            <Nav.Link href="/barcode" className="fs-5" style={{ marginRight: '15px' }}>바코드 스캔</Nav.Link>
            {auth.isAuthenticated && auth.me.role == "ADMIN" ? (
              <>
                <Nav.Link href="/users" className="fs-5" style={{ marginRight: '15px' }}>회원관리</Nav.Link>
                <Nav.Link href="/reciepts" className="fs-5" style={{ marginRight: '15px' }}>접수관리</Nav.Link>
              </>
            ) : (
              <></>
            )}
            {auth.isAuthenticated ? (
              <>
                <Nav.Link href="/process-control" className="fs-5" style={{ marginRight: '15px' }}>공정관리</Nav.Link>
                <NavDropdown title={auth.me.username} id="basic-nav-dropdown" className="fs-5" style={{ marginRight: '15px' }}>
                  <NavDropdown.Item href={`/${auth.me.username}`}>내 정보</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={onLogOut}>로그아웃</NavDropdown.Item>
                </NavDropdown>
              </>
            ) : (
              <Nav.Link className="ml-auto fs-5" href="/login">Login</Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default compose(withRouter, connect(mapStateToProps, { logOutUser }))(AppNavbar);
